<template>

<!-- EDIT HERE -->

<div class="container">
<section class="section rules text-center" id="rules">
<div class="tabs">
  <div class="spoon is-medium is-primary">Rules</div>
</div>

<h3>General Server Rules</h3>
<ul class="text-start">
<li>No Racism. Can't believe that this has to be said but, yea. This isn't before electricity days. In case you were unaware, racist remarks are unacceptable in the server and in discord, for any race. You will be banned, no excuses.</li>
<li>No pornographic images will be allowed at all. Admins get to see all the images added to the game and will remove them. Repeat removals will result in the player loosing their privleges to use /sil</li>
<li>Voice and written chat is used to exchange information, opinions, etc. Any form of spam, provocation, advertising of other servers, politics or spreading hatred will be punished by temporary or permanent blocking of the account. Do not abuse CAPS LOCK.</li>
<li> Do not enter anyone's base unless you are invited. Since this a gray area lets further define this.
  This means their actual structure, not walled off or grounds that they have building privs.
  Sleeping bags are able to be damaged, don't put your bags on obviously claimed lands, or they could be destroyed by them

  This rule is about preventing harassing players from suddenly sleeping in your base, NOT a tattle tale system of justice.
  If its not your base, do not enter a window, door, or other opening into a base, under ANY! circumstance (except on purge)
  If it is your base, use protective measures like window bars etc. its obvious.
  Do not leave your mini, car, horse or other spawn able junk on/in/around others houses or roofs

  Administrative punishments could be administered or forgiven. It is still rust after all. If the behavior is deemed to be:
  Toxic, Harassing, meant to impair a players enjoyment of the server, you can count on getting hit with a hammer of some kind.
  one last note, this is still rust, handle it yourself first before making tickets, were here to administrate, not mediate</li>
<li>The use of bugs, cheats or other external software will result in a permanent ban.</li>
<li>Respect others and do not be rude - Do not make the game difficult for others, try to help.</li>
<li>Stealing from others is strictly prohibited and will result in a ban. (this does not include monuments, we do not have a calling monument or FIFO system. Play nice, share if more then one want to do the monument at the same time)</li>
<li>Impersonating players or admin/moderators is strictly prohibited.</li>
<li>Any error noticed by the player should be immediately reported to the server admin/moderators.</li>
<li>The admin/mods is not responsible for your buildings and items, we do not return your equipment or repair any losses. (this includes failures to get crates from bases and vehicles like Bradleys)</li>
<li> It is prohibited to wall off any monument, any section of a monument, or any public quarry.</li>
<li>While there is no specific limit on base sizes, we ask groups not to build bigger than they need. Any mega-bases will be required to downsize. An excessive amount of external deployables is prohibited, and is grounds for removal by admins</li>
<li>DO NOT log off inside safe zones. The NPC's will kill you and your bag will despawn. Admin/Mods will not return lost items.</li>
<li>English only in public chat.</li>
<li>Make sure to keep enough resources in your TC to maintain your base. Admin/Mods will not refund items lost to decay.</li>
<li>No baiting players to be terrible people. This includes egging someone on to say something to get themselves banned.</li>
</ul>
<h3>Town Rules</h3>
<ul class="text-start">
<li>Town shops can not be your main base.</li>
<li>Town shops are allowed 1 large furnace, and 1 refinery, any small or electric furnaces need to be inside the structure</li>
<li>Please not use a excess of lights. (If there is an excess of lights, this could be grounds for removal/downsizing by admin/mods)</li>
<li>No pornographic images will be allowed on your shop. </li>
<li>Town shops are limited to 30 building foundations, including triangles.</li>
<li>Town shops must have public access. Players are allowed to enter</li>
<li>Town shops are required to have at least one vending machine, with stock in it with 48 hours of building placement.</li>
</ul>
<h3>Raid Rules</h3>
<ul class="text-start">
<li>After you break all loot boxes and your 10 minutes are up, the base will despawn and the barrier will drop. Other players can loot anything that is left on the ground. Be sure to loot everything you want to keep before the 10 minutes is up.</li>
<li>If you buy a raid, be sure to bring enough boom. All raid base levels will come in all varieties. Come prepared for anything!!</li>
<li>Admin/Mods will not refund any boom if you can't finish a raid.</li>
<li>Admin/Mods will not refund RP if you don't like the raid you purchased.</li>
<li>Admin/Mods will only remove and refund rep if there is a issue with the base itself. (exp: Spawns inside a mountain, underwater, etc.)</li>
<li>Anything you build inside the raid zone, will de-spawn 30 minutes after the TC is broken.  (exp: boxes, ladders, etc.)</li>
<li>Do not leave raid spawn platforms, bags, near the base after its finished. These cause the bases not to spawn and may be deleted by staff without warning, or compensation of contents.</li>
</ul>
<h3>Raid Island Rules</h3>
<ul class="text-start">
<li>The customized flat island areas of the maps are to help promote raids being able to spawn. DO NOT:
  put down a TC, foundations, or anything you expect to keep there. Mods will randomly check the area and delete on sight, with no warning. Don't get your feelings hurt because you do this and action is taken, its for the benefit of the rest of the server.</li>
</ul>
<h3>Custom map buildable structure Rules</h3>
<ul class="text-start">
<li>These are things like floating islands, glass houses, or other player buildable areas in a custom map.  This is not the ice lakes, or other sought after spots.
  Do not place a tc, and not build there within a few days (3 if you need a number). The staff may remove the TC and foundations and will not refund materials in them. Or may set the area to become raid-able, and another player will grab your loot and take over the area. We feel that 3 days is more than enough time to find some trees and build out at least something. Do not squat on the customized places for players to build</li>
</ul>

</section>
</div>

<!-- EDIT HERE -->

</template>


<script>
export default {
  name: 'rulesSection'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.store {
	padding-top: 10rem;
}
#rules > h3 {
	color: var(--color-highlight);
}
ul.text-start > li {
	color: var(--color-mute);
}
</style>
